import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
// Constants
import {
  HOME_PAGE_URL,
} from './utils/Constants';
// Components
import LoadingScreen from './components/utils/LoadingScreen'
// Normal import for HomePage (no lazy loading)
import HomePage from './pages/home/HomePage';
// Lazy-loaded Pages
const Error404 = lazy(() => import('./pages/error/Error404'));

function App() {


  return (
    <>
      {/* Suspense component wraps the lazy-loaded components */}
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          {/* Main page routes */}
          <Route path={HOME_PAGE_URL} index element={<HomePage />} />

          {/* Error routes */}
          <Route path='*' element={<Error404 />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
