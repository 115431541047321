import React from 'react';
// Components
// Images
import LogoMain from '../../assets/images/logos/byte-toast-studio-logo-web-developer-transparent-svg.svg';

function HomePageMainContainer() {
  return (
    <main role='main' className='grid w-full h-full'>
      <div className='grid items-center justify-center w-full h-full'>
        <section className='grid h-fit'>
          <div>
            <figure>
              <img
                src={LogoMain}
                className='min-w-44 md:min-w-52 lg:min-w-72'
                alt='Byte Toast Studio logo'
              />
            </figure>
            <div className='text-center text-xl py-4'>
              <h1>Coming soon...</h1>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default HomePageMainContainer;
